"use strict";

import {
  refresh_basket_compact,
  refresh_basket,
} from "./noorcom/basket_compact";

var oscar = (function (o, $) {
  // Replicate Django's flash messages so they can be used by AJAX callbacks.
  o.messages = {
    addMessage: function (tag, msg) {
      var msgHTML =
        '<div class="alert fade show alert-' +
        tag +
        '">' +
        '<a href="#" class="close" data-dismiss="alert">&times;</a>' +
        msg +
        "</div>";
      $("#messages").append($(msgHTML));
    },
    debug: function (msg) {
      o.messages.addMessage("debug", msg);
    },
    info: function (msg) {
      o.messages.addMessag("info", msg);
    },
    success: function (msg) {
      o.messages.addMessage("success", msg);
    },
    warning: function (msg) {
      o.messages.addMessage("warning", msg);
    },
    error: function (msg) {
      o.messages.addMessage("danger", msg);
    },
    clear: function () {
      $("#messages").html("");
    },
    scrollTo: function () {
      $("html").animate({ scrollTop: $("#messages").offset().top });
    },
  };

  // Notifications inbox within 'my account' section.
  o.notifications = {
    init: function () {
      $('a[data-behaviours~="archive"]').click(function () {
        o.notifications.checkAndSubmit($(this), "archive");
      });
      $('a[data-behaviours~="delete"]').click(function () {
        o.notifications.checkAndSubmit($(this), "delete");
      });
    },
    checkAndSubmit: function ($ele, btn_val) {
      $ele.closest("tr").find("input").attr("checked", "checked");
      $ele
        .closest("form")
        .find('button[value="' + btn_val + '"]')
        .click();
      return false;
    },
  };

  // Site-wide forms events
  o.forms = {
    init: function () {
      // Forms with this behaviour are 'locked' once they are submitted to
      // prevent multiple submissions
      $('form[data-behaviours~="lock"]').submit(o.forms.submitIfNotLocked);
    },
    submitIfNotLocked: function (event) {
      var $form = $(this);
      if ($form.data("locked")) {
        return false;
      }
      $form.data("locked", true);
    },
  };

  o.basket = {
    is_form_being_submitted: false,
    init: function (options) {
      if (typeof options == "undefined") {
        options = { basketURL: document.URL };
      }
      o.basket.url = options.basketURL || document.URL;

      var $basket = $(".basket");

      $basket.on(
        "click",
        '#basket_formset a[data-behaviours~="remove"]',
        function (event) {
          o.basket.checkAndSubmit($(this), "form", "DELETE");
          event.preventDefault();
        }
      );
      $basket.on(
        "click",
        '#basket_formset a[data-behaviours~="save"]',
        function (event) {
          o.basket.checkAndSubmit($(this), "form", "save_for_later");
          event.preventDefault();
        }
      );
      $basket.on(
        "click",
        '#saved_basket_formset a[data-behaviours~="move"]',
        function (event) {
          o.basket.checkAndSubmit($(this), "saved", "move_to_basket");
        }
      );
      $basket.on(
        "click",
        '#saved_basket_formset a[data-behaviours~="remove"]',
        function (event) {
          o.basket.checkAndSubmit($(this), "saved", "DELETE");
          event.preventDefault();
        }
      );
      $basket.on("submit", "#basket_formset", o.basket.submitBasketForm);
      $basket.on("click", "#email_quote_form_link a", function (event) {
        o.basket.showEmailQuoteForm();
        event.preventDefault();
      });
      $basket.on("click", "#email_quote_form_cancel", function (event) {
        o.basket.hideEmailQuoteForm();
        event.preventDefault();
      });
      if (window.location.hash == "#email-quote") {
        o.basket.showEmailQuoteForm();
      }
      $(document).on("basket_modified", function (e) {
        refresh_basket();
      });
    },
    submitBasketForm: function (event) {
      $("#messages").html("");
      var payload = $("#basket_formset").serializeArray();
      $.post(o.basket.url, payload, o.basket.submitFormSuccess, "json");
      if (event) {
        event.preventDefault();
      }
    },
    submitFormSuccess: function (data) {
      var basket_inner = $(data.content_html).html();
      $(".basket").html(basket_inner);
      refresh_basket_compact(data);

      var event = { type: "basket_modified", data: data };
      $(document).trigger("broadcast", { event: event });

      // Show any flash messages
      o.messages.clear();
      for (var level in data.messages) {
        for (var i = 0; i < data.messages[level].length; i++) {
          o.messages[level](data.messages[level][i]);
        }
      }
      o.basket.is_form_being_submitted = false;

      $(document).trigger("basket_formset_changed", {
        ga_events: data.ga_events,
      });
    },
    showEmailQuoteForm: function () {
      $("#email_quote_form_container").show();
      $("#email_quote_form_link").hide();
      $("#id_email").focus();
    },
    hideEmailQuoteForm: function () {
      $("#email_quote_form_container").hide();
      $("#email_quote_form_link").show();
    },
    checkAndSubmit: function ($ele, formPrefix, idSuffix) {
      if (o.basket.is_form_being_submitted) {
        return;
      }
      var formID = $ele.attr("data-id");
      var inputID = "#id_" + formPrefix + "-" + formID + "-" + idSuffix;
      $(inputID).attr("checked", "checked");
      $ele.closest("form").submit();
      o.basket.is_form_being_submitted = true;
    },
  };

  o.init = function () {
    o.forms.init();
  };

  return o;
})(oscar || {}, jQuery);

export default oscar;
