"use-strict";

$(function() {
  const hideFilterButton = $("#filters-mobile-hide-btn");
  $('#filters-mobile').on('show.bs.collapse', function() {
    hideFilterButton.removeClass("d-none");
  }).on('hide.bs.collapse', function() {
    hideFilterButton.addClass("d-none");
  });
});
