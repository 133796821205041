"use strict";

import "./components/noorcom/sentry.js";
import "./components/noorcom/utils.js";

import "bootstrap/js/dist/util";
import "bootstrap/js/dist/alert";
import "bootstrap/js/dist/button";
import "bootstrap/js/dist/collapse";
import "bootstrap/js/dist/dropdown";
import "./components/noorcom/google_analytics.js"; // Load this early to gtag is defined early
import "./components/noorcom/basket_compact.js";
import "./components/noorcom/basket_content.js";
import "./components/noorcom/header_search.js";
import "./components/noorcom/catalogue_filters.js";
import "./components/noorcom/countdown_timer.js";
import "./components/noorcom/page_broadcast.js";
import "./components/noorcom/page_btn.js";
import "./components/noorcom/page_carousel.js";
import "./components/noorcom/page_csrf.js";
import "./components/noorcom/page_reload.js";
import "./components/noorcom/page_tooltip.js";
import "./components/noorcom/recaptcha.js";
import "./components/noorcom/facebook_pixel.js";
import "./components/noorcom/voucher_form.js";
import oscar from "./components/oscar_ui.js";

$(function () {
  oscar.init();
});
